<template>
  <div :class="['badge', classComputed]">
    <slot />
  </div>
</template>

<script lang="ts" setup>
interface IProps {
  size?: 'sm' | 'md' | 'lg';
  variant: 'primary' | 'red' | 'success' | 'warning';
}

const props = defineProps<IProps>();

const classComputed = computed(() => {
  return [props.size && `badge--${props.size}`, props.variant && `badge--${props.variant}`];
});
</script>

<style lang="scss" src="./Badge.scss" />
